.dashboard {
  display: grid;
  background-color: white;
  grid-template-rows: max-content auto;
}

.status-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 0.75rem;
  padding: 0.75rem;
  background-color: var(--mdb-gray-200);
  border-radius: 0.25rem;
  line-height: 1em;
}
