@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a.link {
  cursor: pointer;
}

a.link:hover {
  text-decoration: underline;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.w-max-content {
  width: max-content;
}

.fs-smaller-80 {
  font-size: 0.8em;
}

.removable-badge {
  padding: 0.35em;
  display: flex;
  align-items: center;
  justify-items: center;
  font-size: 0.8em;
  line-height: 1;
  border-radius: 0.27rem !important;
  color: var(--mdb-gray-700);
  background-color: var(--mdb-gray-300);
}

.removable-badge:hover {
  background-color: var(--mdb-gray-600);
  color: var(--mdb-gray-300);
}

.control-tag > .badge {
  padding: 0.45em 0.65em 0.4em !important;
  font-weight: normal !important;
}

.cursor-default {
  cursor: default;
}

:root {
  --status-yes: var(--mdb-success);
  --status-mostly: #fbc02d;
  --status-partly: #f57f17;
  --status-no: var(--mdb-danger);
  --status-acceptable: var(--mdb-success);
  --status-tolerable: #f57f17;
  --status-not-acceptable: var(--mdb-danger);
  --status-na: var(--mdb-gray-400);
  --status-no-response: var(--mdb-dark);
}

.modal-content {
  max-height: calc(100vh - (2 * 1.75rem));
}

.navbar {
  padding: 0em !important;
}

@media screen and (max-width: 576px) {
  .modal-content {
    max-height: 100vh;
  }

  .nav-link {
    padding-top: 0em !important;
    text-align: end;
  }
}

@media screen and (min-width: 576px) {
  .modal-dialog.modal-fullscreen-sm-down {
    max-width: 800px;
  }

  #filters-modal {
    max-width: 1200px;
  }
}

.modal-content {
  border: 1px solid var(--mdb-gray-400) !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.modal-content > .modal-header {
  border-bottom: 2px solid #dee2e6;
}

.modal-content > .modal-body {
  overflow: auto;
}

.modal-content > .modal-footer {
  border-top: 2px solid #dee2e6;
  background-color: white;
}

.link-disabled > a {
  color: var(--mdb-gray-800);
  cursor: default;
}

.link-disabled > a:hover {
  color: var(--mdb-gray-800);
}

.breadcrumb-item a,
.breadcrumb-item:before {
  color: white !important;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item:last-child a {
  pointer-events: none;
}

.tooltip-inner {
  max-width: unset !important;
  white-space: unset !important;
}

.tooltip-inner > * {
  width: fit-content !important;
}

.bg-yes {
  background-color: var(--status-yes);
}

.bg-mostly {
  background-color: var(--status-mostly);
}

.bg-partly {
  background-color: var(--status-partly);
}

.bg-no {
  background-color: var(--status-no);
}

.bg-acceptable {
  background-color: var(--status-acceptable);
}

.bg-tolerable {
  background-color: var(--status-tolerable);
}

.bg-not-acceptable {
  background-color: var(--status-not-acceptable);
}

.bg-na {
  background-color: var(--status-na);
}

.bg-no-response {
  background-color: var(--status-no-response);
}

.text-yes {
  color: var(--status-yes) !important;
}

.text-mostly {
  color: var(--status-mostly) !important;
}

.text-partly {
  color: var(--status-partly) !important;
}

.text-no {
  color: var(--status-no) !important;
}

.text-acceptable {
  color: var(--status-acceptable) !important;
}

.text-tolerable {
  color: var(--status-tolerable) !important;
}

.text-not-acceptable {
  color: var(--status-not-acceptable) !important;
}

.text-na {
  color: var(--status-na) !important;
}

.text-no-response {
  color: var(--status-no-response) !important;
}

.d-section {
  display: grid;
  grid-template-rows: max-content auto;
  height: 100%;
}

.form-check-input[type='radio']:after,
.form-check-input[type='radio']:checked:after {
  z-index: unset !important;
}

.ui-lib-dropdown-menu > .dropdown-item {
  color: var(--mdb-dark);
  padding: 0.25rem 1rem !important;
}

.ui-lib-dropdown .form-control {
  padding: 0.25rem 1.75rem 0.25rem 0.5rem !important;
}

#ui-lib-side-nav:last-child {
  font-weight: normal !important;
}

.auto-link-textarea {
  overflow: auto;
  white-space: pre-wrap;
  height: 3.8rem;
}

.auto-link-textarea:empty:before {
  content: attr(placeholder);
  color: var(--mdb-gray-600);
}

.likified-link {
  text-decoration: underline;
}

.risk-owner-request-response {
  max-width: 11rem;
}

.login-copyright {
  width: 100%;
  bottom: 0;
  position: sticky;
  padding: 0.25rem;
  padding-left: 0.5rem;
  border-color: var(--mdb-white); 
  background-color: var(--mdb-white);
}

.d-section-with-footer {
  display: grid;
  grid-template-rows: auto max-content;
  height: 100%;
}

.modal-header {
  padding: 1rem 1rem 0.4rem 1rem;
}

.modify-status-component-textarea-container {
  width: 100%;
  overflow-x: auto;
}

.modify-status-component-toptext {
  width: 50%;
  padding: 8px;
  border: 1px solid var(--mdb-gray-700);
  border-radius: 4px;
  resize: none;
}

.modify-status-component-subtext {
  width: 90%;
  padding: 8px;
  border: 1px solid var(--mdb-gray-700);
  border-radius: 4px;
  margin: 0.5rem 0 0 0;
  resize: none;
  white-space: nowrap;
  overflow-x: hidden;
}

.character-length {
  color: var(--mdb-gray-500);
  font-size:0.8rem;
  margin-left:0.2rem;
}

.registration-link {
  color: var(--mdb-primary);
  cursor: pointer;
  text-decoration: underline;
}

.character-count-max {
  color: var(--mdb-danger);
  font-weight: bold;
}

fieldset:disabled {
  opacity: 0.5;
 }
 